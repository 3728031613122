<template>
  <div class="createApp">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <el-form
          label-position="right"
          label-width="120px"
          :model="formInline"
          style="width: 440px"
          :rules="rules"
          ref="form"
        >
          <el-form-item label="应用名" prop="applicationName">
            <el-input
              type="text"
              v-model="formInline.applicationName"
              placeholder="请输入应用名"
            ></el-input>
          </el-form-item>
          <el-form-item label="应用包名" prop="productId">
            <el-input
              type="text"
              v-model="formInline.productId"
              placeholder="请输入应用包名"
            ></el-input>
          </el-form-item>
          <el-form-item label="平台" prop="platform">
            <el-select v-model.trim="formInline.platform" filterable>
              <el-option label="Android" value="0"></el-option>
              <el-option label="IOS" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="应用描述" prop="applicationDesc">
            <el-input
              type="textarea"
              placeholder="请输入应用描述"
              :maxlength="100"
              :autosize="{ minRows: 4, maxRows: 8 }"
              v-model="formInline.applicationDesc"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center; margin-top: 100px">
          <el-button type="primary" @click="submitData">创建</el-button>
          <el-button type="info" @click="$emit('dialogShow', false)">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let validateProductId = (rule, value, callback) => {
  if (!/^[A-Za-z]([A-Za-z0-9])+(\.[^\u4e00-\u9fa5]+){2}$/.test(value)) {
    callback(new Error("请输入正确的应用包名"));
  } else {
    callback();
  }
};
export default {
  data() {
    return {
      appVersionIdType: "",
      command: "",
      fileUrl: "",
      startTime: "",
      formInline: {},
      rules: {
        applicationName: [{ required: true, message: "请输入应用名", trigger: "blur" }],
        platform: [
          {
            required: true,
            message: "请选择平台",
            trigger: "change",
          },
        ],
        productId: [
          { required: true, message: "请输入应用包名", trigger: "change" },
          { validator: validateProductId, trigger: "blur" },
        ],
        applicationDesc: [
          {
            required: true,
            message: "请输入应用描述",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitData() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/application/version/add", {
              data: {
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state === 0) {
                this.$message({
                  type: "success",
                  message: "创建成功",
                });
                this.$emit("dialogShow", false);
                this.$emit("searchData");
              } else {
                this.$alert(res.desc);
              }
            });
        }
      });
    },
  },
  created() {},
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.createApp {
  max-width: 900px;
}
</style>
